<template>
  <div>
    <div class="Head">
      <div class="HeadingArea--pc">
        <div class="header-menu">
          <ul class="header-menu-inner">
            <li>
              <a href="/">
                <v-icon class="first-icon" color="white" medium>
                  mdi-cellphone-text
                </v-icon>
                <span class="text"
                  >サービス<v-icon class="allow-icon" dense small>
                    mdi-open-in-new
                  </v-icon>
                </span>
              </a>
            </li>
            <li>
              <a href="/myprofile">
                <v-icon class="first-icon" color="white" medium>
                  mdi-account-box-multiple-outline
                </v-icon>
                <span class="text"
                  >My Profile<v-icon class="allow-icon" dense x-small>
                    mdi-open-in-new
                  </v-icon></span
                >
              </a>
            </li>
            <li>
              <a href="/news_release">
                <v-icon class="first-icon" color="white" medium>
                  mdi-newspaper
                </v-icon>
                <span class="text"
                  >ニュースリリース<v-icon class="allow-icon" dense small>
                    mdi-open-in-new
                  </v-icon></span
                >
              </a>
            </li>
            <li>
              <a href="/company">
                <v-icon class="first-icon" color="white" medium>
                  mdi-office-building-outline
                </v-icon>
                <span class="text"
                  >事業・IR情報<v-icon class="allow-icon" dense small>
                    mdi-open-in-new
                  </v-icon></span
                >
              </a>
            </li>
            <li>
              <a href="/support">
                <v-icon class="first-icon" color="white" medium>
                  mdi-face-agent
                </v-icon>
                <span class="text"
                  >サポート<v-icon class="allow-icon" dense small>
                    mdi-open-in-new
                  </v-icon></span
                >
              </a>
            </li>
          </ul>
        </div>
        <div class="header-tools">
          <ul class="header-tools-inner first-block">
            <li class="inline-block">
              <a href="/NotFound">
                <v-icon class="first-icon" color="white" small>
                  mdi-message-question-outline
                </v-icon>
                <span class="align-top">よくあるご質問(FAQ)</span>
              </a>
            </li>
            <li class="inline-block">
              <a href="/NotFound">
                <v-icon class="first-icon" color="white" small>
                  mdi-human-greeting-proximity
                </v-icon>
                <span class="align-top">お問い合わせ</span>
              </a>
            </li>
          </ul>
          <ul class="header-tools-inner second-block">
            <li class="inline-block register-button">
              <a>
                <span class="align-bottom">新規登録</span>
              </a>
            </li>
            <li class="inline-block login-button">
              <a>
                <v-icon color="white" small>
                  mdi-login
                </v-icon>
                <span class="align-bottom">ログイン</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="HeadingArea--mobile">
        <div class="header-menu">
          <ul>
            <li>
              <a class="rb-h-smart-menu__btn">
                <v-icon color="white" medium>
                  mdi-account-plus-outline
                </v-icon>
                新規登録
              </a>
            </li>
            <li>
              <a href="/support" class="rb-h-smart-menu__btn">
                <v-icon color="white" medium>
                  mdi-message-question-outline
                </v-icon>
                ヘルプ
              </a>
            </li>
            <li>
              <v-menu left bottom :offset-y="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="white" medium>
                      mdi-table-of-contents
                    </v-icon>
                    <span>メニュー</span>
                  </v-btn>
                </template>

                <v-list>
                  <div>
                    <v-list-item @click="scrollToAnchorPoint('separator_line')">
                      <v-list-item-title>
                        サービス
                        <v-icon right small>
                          mdi-cellphone-text
                        </v-icon>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item href="/myprofile">
                      <v-list-item-title>
                        My Profile
                        <v-icon right small>
                          mdi-account-box-multiple-outline
                        </v-icon>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item href="/news_release">
                      <v-list-item-title>
                        ニュースリリース
                        <v-icon right small>
                          mdi-newspaper
                        </v-icon>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item href="/company">
                      <v-list-item-title>
                        事業・IR情報
                        <v-icon right small>
                          mdi-office-building-outline
                        </v-icon>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item href="/support">
                      <v-list-item-title>
                        サポート
                        <v-icon right small>
                          mdi-face-agent
                        </v-icon>
                      </v-list-item-title>
                    </v-list-item>
                  </div>
                </v-list>
              </v-menu>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="top-title">
        <div class="barGray">
          <h1>
            事業・IR情報
          </h1>
        </div>
      </div>

      <div class="top_image">
        <div class="img">
          <img
            src="https://storage.googleapis.com/bexix-c3ac1.appspot.com/company_top.jpeg"
          />
        </div>
        <div class="c-lead-visual_contents">
          <div class="c-lead-visual_contents_inner">
            <div class="c-lead-panel">
              <div class="c-lead-panel_head">
                <h2 class="elem">
                  ITとSNS業界に手助けを。<br />
                  お客様に愛される個人事業へ
                </h2>
              </div>
              <div class="c-lead-panel_content">
                <p class="elem">
                  Bexixは屋号を変えながら、2017年から個人で開発と提供を進めてまいりました。これからも、お客様のご意見を第一に受け止め、事業の革新につなげてまいります。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="news">
        <h2 class="index-news_head">
          <span>NEWS</span>
        </h2>
        <div class="news_inner">
          <v-tabs background-color="black" dark grow>
            <v-tab>
              すべてのニュース
            </v-tab>
            <v-tab>
              サービス情報
            </v-tab>
            <v-tab>
              プレスリリース
            </v-tab>
            <v-tab>
              お知らせ
            </v-tab>

            <v-tab-item>
              <v-card flat tile>
                <v-card-text>
                  <div class="index-news__tab-content" id="tab-1">
                    <ul class="index-news__list">
                      <li class="index-news__list-item">
                        <p class="index-news__list-category">
                          <span>お知らせ</span>
                        </p>
                        <a>
                          <p class="index-news__list-date">2021.04.27</p>
                          <p class="index-news__list-text">
                            ホームページの大幅リニューアルを行いました。
                          </p>
                        </a>
                      </li>
                      <li class="index-news__list-item">
                        <p class="index-news__list-category">
                          <span>お知らせ</span>
                        </p>
                        <a>
                          <p class="index-news__list-date">2021.03.27</p>
                          <p class="index-news__list-text">
                            プライバシーポリシーの改訂を行いました。ご確認をお願いいたします。
                          </p>
                        </a>
                      </li>
                      <li class="index-news__list-item">
                        <p class="index-news__list-category">
                          <span>お知らせ</span>
                        </p>
                        <a>
                          <p class="index-news__list-date">2021.03.25</p>
                          <p class="index-news__list-text">
                            利用規約、プライバシーポリシーとプロフィールページを追加しました。
                          </p>
                        </a>
                      </li>
                      <li class="index-news__list-item">
                        <p class="index-news__list-category">
                          <span>サービス情報</span>
                        </p>
                        <a>
                          <p class="index-news__list-date">2021.03.22</p>
                          <p class="index-news__list-text">
                            当ホームページの一般公開を始めました。
                          </p>
                        </a>
                      </li>
                    </ul>
                  </div></v-card-text
                >
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat tile>
                <v-card-text>
                  <div class="index-news__tab-content" id="tab-2">
                    <ul class="index-news__list">
                      <li class="index-news__list-item">
                        <p class="index-news__list-category">
                          <span>サービス情報</span>
                        </p>
                        <a>
                          <p class="index-news__list-date">2021.03.22</p>
                          <p class="index-news__list-text">
                            当ホームページの一般公開を始めました。
                          </p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat tile>
                <v-card-text>
                  <div class="index-news__tab-content" id="tab-3">
                    <ul class="index-news__list">
                      <li class="index-news__list-item">
                        <p class="index-news__list-category">
                          <span>プレスリリース</span>
                        </p>
                        <a>
                          <p class="index-news__list-date">2021.XX.XX</p>
                          <p class="index-news__list-text">
                            特にございません。
                          </p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat tile>
                <v-card-text>
                  <div class="index-news__tab-content" id="tab-4">
                    <ul class="index-news__list">
                      <li class="index-news__list-item">
                        <p class="index-news__list-category">
                          <span>お知らせ</span>
                        </p>
                        <a>
                          <p class="index-news__list-date">2021.04.27</p>
                          <p class="index-news__list-text">
                            ホームページの大幅リニューアルを行いました。
                          </p>
                        </a>
                      </li>
                      <li class="index-news__list-item">
                        <p class="index-news__list-category">
                          <span>お知らせ</span>
                        </p>
                        <a>
                          <p class="index-news__list-date">2021.03.27</p>
                          <p class="index-news__list-text">
                            プライバシーポリシーの改訂を行いました。ご確認をお願いいたします。
                          </p>
                        </a>
                      </li>
                      <li class="index-news__list-item">
                        <p class="index-news__list-category">
                          <span>お知らせ</span>
                        </p>
                        <a>
                          <p class="index-news__list-date">2021.03.25</p>
                          <p class="index-news__list-text">
                            利用規約、プライバシーポリシーとプロフィールページを追加しました。
                          </p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.Head {
  background-color: #272727;
  height: auto;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
}

.HeadingArea--pc {
  background-color: #272727;
  height: auto;
  margin: 0 auto;
  padding-bottom: 4px;
  display: table;
}

@media (max-width: 849px) {
  .HeadingArea--pc {
    display: none;
  }
}

.HeadingArea--pc .header-menu {
  display: table-cell;
}

.HeadingArea--pc .header-menu-inner {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.HeadingArea--pc .header-menu-inner > li:first-child:before {
  content: "";
  background: #333333;
  width: 1px;
  top: 9px;
  bottom: 9px;
  position: absolute;
  left: 0;
}

.HeadingArea--pc .header-menu-inner > li:after {
  content: "";
  background: #333333;
  width: 1px;
  top: 9px;
  bottom: 9px;
  position: absolute;
  right: 0;
}

.HeadingArea--pc .header-menu-inner li {
  display: flex;
  align-items: center;
  position: relative;
}

.HeadingArea--pc .header-menu-inner li a {
  padding: 14px 16px;
  text-decoration-line: none;
  text-align: center;
  height: 84px;
}

@media (max-width: 1100px) {
  .HeadingArea--pc .header-menu-inner li a {
    padding: 14px 10px;
  }
}

.HeadingArea--pc .header-menu-inner li a:hover {
  border-bottom: 4px solid #00a0e9;
}

.HeadingArea--pc .header-menu-inner li .first-icon {
  height: 35px;
}

.HeadingArea--pc .header-menu-inner li a .text {
  display: block;
  font-weight: 550;
  font-size: 14px;
  color: #fff;
  margin-left: 16px;
}

.HeadingArea--pc .header-menu-inner li a .allow-icon {
  color: #616161;
  margin-left: 5px;
}

.HeadingArea--pc .header-tools {
  display: table-cell;
  vertical-align: middle;
}

.HeadingArea--pc .header-tools-inner {
  display: block;
  font-size: 14px;
}

.HeadingArea--pc .first-block {
  margin-bottom: 13px;
}

@media (max-width: 1000px) {
  .HeadingArea--pc .first-block {
    display: none;
  }
}

.HeadingArea--pc .second-block {
  text-align: center;
}

.HeadingArea--pc .header-tools-inner li {
  list-style: none;
  display: block;
}

@media (min-width: 1000px) {
  .HeadingArea--pc .header-tools-inner li {
    display: inline-block;
  }
}

@media (min-width: 1000px) {
  .HeadingArea--pc .header-tools-inner li:nth-child(n + 2) {
    margin-left: 25px;
  }
}

@media (max-width: 1000px) {
  .HeadingArea--pc .header-tools-inner li:nth-child(n + 2) {
    margin-top: 15px;
  }
}

.HeadingArea--pc .header-tools-inner li a {
  text-decoration-line: none;
}

.HeadingArea--pc .header-tools-inner li a:hover {
  opacity: 0.5;
}

.HeadingArea--pc .header-tools-inner li a .v-icon {
  margin-right: 5px;
}

.HeadingArea--pc .header-tools-inner li a .align-top {
  color: #fff;
}

.HeadingArea--pc .header-tools-inner .login-button a {
  padding: 0.2em 0.8em;
  color: #fff;
  border: solid 1px #fff;
  border-radius: 10px;
}

.HeadingArea--pc .header-tools-inner .register-button a {
  background-color: #fff;
  padding: 0.2em 1.4em;
  color: #272727;
  font-weight: 600;
  border-radius: 10px;
  display: block;
  width: 100%;
}

.HeadingArea--mobile {
  background-color: #272727;
  height: auto;
  margin: 0 auto;
  padding: 7px;
}

@media (min-width: 850px) {
  .HeadingArea--mobile {
    display: none;
  }
}

.HeadingArea--mobile .header-menu ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.HeadingArea--mobile .header-menu li {
  list-style: none;
  display: inline-block;
  height: 48px;
}

.HeadingArea--mobile .header-menu li:nth-child(n + 2) {
  margin-left: 15px;
}

.HeadingArea--mobile .header-menu li a,
.HeadingArea--mobile .header-menu li .v-btn {
  text-decoration-line: none;
  font-size: 13px;
  color: #fff;
  text-align: center;
  width: auto;
}

.HeadingArea--mobile .header-menu li i {
  display: block;
  margin: 0 auto;
}

.HeadingArea--mobile .header-menu .v-btn {
  height: 100%;
}

.HeadingArea--mobile .header-menu .v-btn__content {
  display: block !important;
  text-align: center;
}

.HeadingArea--mobile .header-menu .v-btn:before {
  display: none;
}

.HeadingArea--mobile .header-menu .v-ripple__container {
  display: none;
}

.top-title {
  position: block;
  width: 70%;
  margin: 50px auto;
}

.top-title .barGray {
  border-left: 4px solid #979797;
  padding-left: 24px;
  margin-bottom: 40px;
}

.top_image img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  z-index: 0;
  position: absolute;
}

.c-lead-visual_contents {
  display: flex;
  position: relative;
  max-width: 1024px;
  margin: 0 auto;
  padding-top: 98px;
  z-index: 1;
  margin-bottom: 30px;
}

.c-lead-visual_contents_inner {
  width: 440px;
  box-shadow: 8px 8px 20px 0 rgb(0 0 0 / 10%);
}

.c-lead-panel {
  padding: 40px 32px;
  background: rgba(255, 255, 255, 0.95);
}

.c-lead-panel_head .elem {
  color: #0068b7;
  font-size: 21.5px;
  font-weight: bolder;
}

.c-lead-panel_content {
  margin-top: 18px;
}

.c-lead-panel_content .elem {
  color: #333;
  font-size: 15px;
  font-weight: normal;
}

.news {
  margin-bottom: 80px;
}

.index-news_head {
  display: flex;
  font-weight: bold;
  font-size: 26px;
  max-width: 860px;
  margin: 55px auto 25px;
}

.news_inner {
  background: #fff;
  border-bottom-right-radius: 40px;
  position: relative;
  z-index: 1;
  max-width: 850px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-shadow: 3px 3px 2px 0 rgb(0 0 0 / 2%);
  box-shadow: 3px 3px 2px 0 rgb(0 0 0 / 2%);
}

.news_inner li {
  list-style: none;
  display: flex;
}

.news_inner a {
  text-decoration-line: none;
  display: flex;
  color: #333333;
  height: auto;
}

.news_inner a:hover {
  opacity: 0.7;
}

.index-news__list-item {
  margin-top: 20px;
}

.index-news__list-category {
  width: 120px;
  margin-right: 18px;
}

.index-news__list-category span {
  display: block;
  width: 100%;
  background: #404040;
  color: #fff;
  font-size: 10px;
  text-align: center;
  line-height: 1;
  padding: 4px 0.5em;
}

.index-news__list-date {
  color: #797979;
  font-weight: bold;
  font-size: 12px;
  margin-right: 20px;
}
</style>
